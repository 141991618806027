<template>
  <div class="self-swiper" :style="`--proportion: ${100 / tabNav.length}%`">
    <div class="tab-nav">
      <div :class="['nav', item.select && 'select-nav']" @click="clickNav(idx)"
        v-for="(item, idx) in tabNav" :key="idx">
        <span>{{ item.itemName }}</span>
        <span class="tag" v-if="!item.select && item.tag > 0">{{ item.tag }}</span>
      </div>
    </div>

    <div class="swiper">
      <div class="swiper-list" :style="{width: tabNav.length * 100 + '%', left: -cur_tabNav_idx * 100 + '%'}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabNav: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cur_tabNav_idx: 0
    }
  },
  methods: {
    clickNav(idx) {
      this.cur_tabNav_idx = idx
      this.$emit('clickNav', idx)
    },
  }
}
</script>

<style scoped lang="scss">
.self-swiper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .tab-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.875rem;
    
    .nav {
      width: calc(var(--proportion) - .5rem);
      padding: .625rem;
      text-align: center;
      border-bottom: 3px solid #F0F0EF;
      font-size: 22px;
      font-weight: 700;
      color: #6D6D6D;
      cursor: pointer;
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #C82C2C;
        color: #fff;
        font-size: 1.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .select-nav {
      border-bottom-color: #1B7379;
      color: #1B7379;
    }
  }

  .swiper {
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-list {
      position: relative;
      top: 0;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      transition: left .5s;
      padding-top: .625rem;
      .swiper-item {
        width: var(--proportion);
      }
    }
  }
}
</style>