<template>
  <div class="qr-codes">
    <div class="compulsory">
      <span class="label">*Compulsory</span>
      <!-- <span class="value">Last Published {{ beacons.publishedAt }}</span> -->
    </div>

    <el-form :model="beacons" status-icon :rules="rules" ref="ruleForm"
     label-width="260px" class="ruleForm" label-position="left">
      <div class="content">iBeacon Information</div>
      <el-form-item label="iBeacon UUID" prop="uuid">
       <!-- <span class="value"> {{ beacons.uuid }}</span> -->
        <el-input v-model="beacons.uuid" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="iBeacon MAJOR Value" prop="major">
        <!-- <span class="value"> {{ beacons.major }}</span> -->
        <el-input v-model.number="beacons.major" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="iBeacon MINOR Value" prop="minor">
        <!-- <span class="value"> {{ beacons.minor }}</span> -->
        <el-input v-model.number="beacons.minor" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="iBeacon LOC Value" prop="minor">
        <!-- <span class="value"> {{ beacons.minor }}</span> -->
        <el-input v-model="beacons.loc" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <!-- <div class="btn" @click="save">Save</div> -->

    <div class="line"></div>

  </div>
</template>

<script>
import { editBeacons } from '@/api/device'

export default {
  props: {
    beacons: {
      type: Object
    }
  },
  data() {
    return {
      groups: [],
      rules: {
        uuid: [
          { required: true, message: 'Please Enter IBeacon UUID', trigger: 'blur' },
        ],
        major: [
          { required: true, message: 'Please Enter IBeacon MAJOR Value', trigger: 'blur' },
        ],
        minor: [
          { required: true, message: 'Please Enter IBeacon MINOR Value', trigger: 'blur' },
        ],
        loc: [
          { required: true, message: 'Please Enter Location ID (Loc)', trigger: 'blur' },
        ],
      },
    }
  }, 
  methods: {
    save() {
      let form = {
        ...this.beacons
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editBeacons(form.id, form).then(() => {
            this.this.createCode()
          })
        }
      })
    },
  },
  mounted() {
    
  }
}
</script>

<style scoped lang="scss">
.qr-codes {
  width: 100%;

  .compulsory { 
    font-size: 1.125rem;
    color:#1B7379;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.1875rem;
    margin-top: 1.875rem;
  }
  .ruleForm {
    margin-top: 1.875rem;
    width: 67%;

    .value {
      font-size: 1.125rem;
    }

  }
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  .btn {
    width: 17.5rem;
    height: 3.75rem;
    background-color: #1B7379;
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 1.875rem;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #F0F0EF;
    margin: 3.125rem 0;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    color: #1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    
    span {
      margin-right: .9375rem;
    }
  }

  .qrcode-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .single {
      width: 30rem;
      height: 28.125rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 1.875rem;
      .title {
        color: #1B7379;
        font-weight: 700;
        font-size: 1.125rem;
        margin-bottom: .9375rem;
      }
      
      .card {
        width: 100%;
        flex: 1;
        background-color: #FAFAFA;
        border-radius: 1.25rem;
        padding: 1.25rem;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .qrcode-single {
          width: 11.25rem;
          .qrcode-img {
            width: 11.25rem;
            height: 11.25rem;
            // height: auto;
            margin-bottom: .625rem;
            border: 1px solid #6D6D6D;
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            box-sizing: border-box;

            & > img {
              width: 100%;
              height: 100%;
            }
          }

          .label {
            display: block;
            width: 100%;
            color: #000;
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: .625rem;
          }

          .stamp, .stamp-red {
            display: block;
            width: 100%;
            color: #1B7379;
            font-size: 1.125rem;
            margin-bottom: .625rem;
          }

          .stamp-red {
            color: #C82C2C;
          }
        }
      }
    }
  }
} 
</style>