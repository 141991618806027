<template>
  <div class="offers-detail">
    <div class="header">Offer</div>

    <div class="container">
      <div>
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          label-width="260px"
          class="ruleForm"
          label-position="top"
        >
          <el-row type="flex" justify="space-between">
            <el-col
              :span="12"
              class="current"
              style="--bgcolor: #1b7379; --title: 'Create'; --textcolor: #fff"
              v-for="(item,index) in ruleForm.offers" :key="index"
            >
              <el-form-item label="Item (TC)">
                <el-input
                  v-model="item.item_tc"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="Item (EN)">
                <el-input
                  v-model="item.item_en"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="Number of Stamps Costing">
                <div class="value">{{ item.stampCostingNumber }}</div>
              </el-form-item>
              <el-form-item label="Date" v-if="false">
                <div class="date">
                  <el-date-picker
                    v-model="item.dates"
                    type="daterange"
                    format="yyyy-MM-dd"
                    @change="handleDate(index,$event)"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item label="T&C (TC)">
                <el-input
                  type="textarea"
                  v-model="item.general_tc"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="T&C (EN)">
                <el-input
                  type="textarea"
                  v-model="item.general_en"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
    },
  },
  data() {
    return {
      dates: [],
      ruleForm: {},
    };
  },
  computed: {
    offersResult: function() {
      return this.ruleForm.offers.filter(its => {
        if(!its.item_tc.trim() 
          && !its.item_en.trim() 
          && !its.general_tc.trim() 
          && !its.general_en.trim()) {
            return null
        } return its
      })
    }
  },
  methods: {
    handleDate(index,v) {
      let [start, end] = v;
      this.ruleForm['offers'][index].date =
        new Date(start).format("yyyy-MM-dd") +
        " - " +
        new Date(end).format("yyyy-MM-dd");
        this.$forceUpdate()
    },
    minus(index) {
      this.ruleForm['offers'][index].stampCostingNumber--;
    },
    plus(index) {
      this.ruleForm['offers'][index].stampCostingNumber++;
    },
    // changeInput(cte) {
    //   this.ruleForm.stampCostingNumber = cte.replace(/\D/g, "");
    // },
    reset() {
      this.ruleForm = JSON.parse(JSON.stringify(this.offer));
    },
  },
  mounted() {
    this.reset();
    // console.log(this.ruleForm);
  },
};
</script>

<style lang="scss" scoped>
.offers-detail {
  width: 100%;
  margin-top: 1.25rem;
  height: fit-content;
  border-radius: 1.25rem;
  background-color: #fafafa;
  .header {
    width: 100%;
    height: 4.25rem;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: #1b7379;
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .container {
    width: 100%;
    padding: 1.875rem 1.25rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
    .current {
      width: 37.5rem;
      border-radius: 0.9375rem;
      border: 1px solid #bcbcbc;
      padding: 1.25rem 1.5625rem 1.875rem 1.5625rem;
      box-sizing: border-box;
      position: relative;
      height: fit-content;

      .value {
        font-size: 1.125rem;
        color: #000;
      }

      .btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.25rem;
      }

      // &:after {
      //   content: var(--title, 'Current');
      //   position: absolute;
      //   top: -1.5rem;
      //   left: 1.25rem;
      //   height: 3rem;
      //   padding: 0 1.25rem;
      //   background-color: var(--bgcolor, #F0F0EF);
      //   border-radius: .9375rem;
      //   line-height: 3rem;
      //   color: var(--textcolor, #000);
      //   font-size: 1.125rem;
      //   font-weight: 700;
      // }

      .handler-data {
        display: flex;
        align-items: center;

        img {
          width: 2.125rem;
          height: auto;
          cursor: pointer;
        }

        .ipt {
          width: 6.25rem;
          margin: 0 0.625rem;
        }
      }

      .date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #000;
        font-size: 1.125rem;

        img {
          width: 1.875rem;
          height: auto;
        }
      }

      .operation {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.25rem;

        .update {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            color: #1b7379;
            font-weight: 700;
            font-size: 1.125rem;
            margin-right: 2.5rem;
            cursor: pointer;
          }
        }
      }
      .card-single {
        width: 100%;
        margin-top: 1.25rem;
        color: #000;
        font-size: 1.125rem;
        .label {
          font-weight: 700;
          margin-bottom: 0.625rem;
        }
        .expand {
          width: 100%;
          border-bottom: 1px solid #1b7379;
          padding-bottom: 0.625rem;
          .t-c {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.625rem 0;
            box-sizing: border-box;

            img {
              width: 1rem;
              height: auto;
            }
          }
          .description {
            width: 100%;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>