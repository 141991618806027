<template>
  <div class="eatery-edit">
    <Module-Header title="Eatery Create New" :leading="true">
      <el-button class="default" @click="save" :disabled="offersDetail.show">Save & Publish</el-button>
    </Module-Header>

    <SelfSwiper :tabNav="tabNav" @clickNav="clickNav">
      <div class="swiper-item"> 
        <Profile type="add" :form="ruleForm" ref="Profile"></Profile>
      </div>
      <div class="swiper-item">
        <offersAdd :offer="offer" ref="offers"></offersAdd>
      </div>
      <div class="swiper-item">
        <qrCodesAdd  :beacons="beacons"></qrCodesAdd>
      </div>
    </SelfSwiper>
    
  </div>
</template>

<script>
import { addEateries } from '@/api/eatery'
import SelfSwiper from '../../components/SelfSwiper/selfSwiper.vue';
import Profile from './components/profile/profile.vue';
import qrCodesAdd from './components/qrCodes/qrCodes_add.vue';
import offersAdd from './components/offers/offers_add.vue';

export default {
  components: {
    SelfSwiper, 
    Profile,
    offersAdd,
    qrCodesAdd
  },
  data() {
    return {
      beacons: {
        uuid: null,
        major: null,
        minor: null
      },
      tabNav: [
        {itemName: 'Profile', select: true, tag: 0},
        {itemName: 'Offers', select: false, tag: 0},
        {itemName: 'QR Codes & iBeacon', select: false, tag: 0},
      ],
      offer:{
        offers: [ 
          {
            item_tc: '',
            item_en: '',
            general_tc: '',
            general_en: '',
            general_tc_select: false,
            general_en_select: false,
            stampCostingNumber: 6,
            date: "",
            dates: [],
            status: 0,
            isDisable: true,
          }, 
          {
            item_tc: '',
            item_en: '',
            general_tc: '',
            general_en: '',
            general_tc_select: false,
            general_en_select: false,
            stampCostingNumber: 12,
            date: "",
            dates: [],
            status: 0,
            isDisable: true,
          }, 
        ],
      },
      
      offersDetail: {
        show: false,
        data: {}
      },
      ruleForm: {
        support_campaign_type: '',
        eaterName_tc: '',
        eaterName_en: '',
        logo: '',
        eateryGroup: '',
        cateringType: '',
        is_bring_your_own_carrier: false, 
        district: '',
        area: '',
        address_tc: '',
        address_en: '',
        mapURL: '',
        contactPerson: '',
        contactNo: '',
        email: '',
        general_tc: '',
        general_en: '',
        username: '',
        password: ''
      },
    };
  },
  methods: {
    clickNav(idx) {
      this.tabNav.filter((its, index) => index == idx ? its.select = true : its.select = false) 
    },
    save() {
      let profile = this.$refs.Profile,
      offers = this.$refs.offers,
      ruleForm = profile.ruleForm
      
      let obj = {
        "name": {
          "zh_hant": ruleForm.eaterName_tc,
          "en": ruleForm.eaterName_en,
        },
        "logo": ruleForm.logo,
        "eatery_group": {
          "id": ruleForm.eateryGroup != '' ? Number(ruleForm.eateryGroup) : 0
        },
        "catering_type": {
          "zh_hant": ruleForm.cateringType.split('/')[1],
          "en": ruleForm.cateringType.split('/')[0]
        },
        "is_bring_your_own_carrier": ruleForm.is_bring_your_own_carrier,
        "location":{
          "district": {
            "zh_hant": ruleForm.district.split('/')[1],
            "en": ruleForm.district.split('/')[0]
          },
          "area": {
            "zh_hant": ruleForm.area.split('/')[1],
            "en": ruleForm.area.split('/')[0]
          },
          "address":{
            "zh_hant": ruleForm.address_tc,
            "en": ruleForm.address_en
          },
          "map_url": ruleForm.mapURL
        },
        "contact": {
          "name": ruleForm.contactPerson,
          "phone_number": ruleForm.contactNo,
          "email_address": ruleForm.email
        },
        "beacon": {...this.beacons},
        "general": {
          "zh_hant": ruleForm.general_tc,
          "en": ruleForm.general_en
        },
        "reward_redemptions": [...offers.offersResult.map((v)=>{
          //  v.startDate = v.date.split(' - ')[0]
          //  v.endDate = v.date.split(' - ')[1]
           v.item = {
              zh_hant: v.item_tc,
              en: v.item_en
            }
            v.general = {
              zh_hant: v.general_tc,
              en: v.general_en
            }
            if(v.stampCostingNumber == 6) v.type = 0
            else v.type = 1
           return v
        })],
        support_campaign_type: ruleForm.support_campaign_type,
        "user": {
          "username": ruleForm.username,
          "password": ruleForm.password
        }
      }

      // addEateries(obj).then(() => {
      //   this.$router.go(-1)
      // })
      profile.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addEateries(obj).then(() => {
            this.$message.success('Successfully Saved')
            this.$router.go(-1)
          })
        }
      })
    },
    updateDataOffers(v) {
      let idx = v.idx
      this.offers[idx] = v
      this.tabOffers()
    },
    tabOffers() {
      this.offersDetail.show = false
    },
    tabOffersEdit(row) {
      this.offersDetail.show = true
      this.offersDetail.data = row
    },
  },
  created(){
    
  },
  mounted(){

  },
};
</script>

<style lang="scss" scoped>
.eatery-edit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
</style>